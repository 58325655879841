import React from 'react'
import Chartui from './ChartUi'

const ChatInbox = ({open}) => {
  return (
    <div className='my-8'>
     <Chartui/>
    </div>
  )
}

export default ChatInbox