import React from 'react';
import { useState } from 'react';
import CustomizeTeam1 from './CustomizeTeams1'
import CustomizeTeam2 from './CustomizeTeam2'
function CustomizeTeams()
{
  
    return(
        <div className='mb-20'>
            
            <CustomizeTeam2/>

        </div>
    );
}
export default CustomizeTeams;